import React from "react";
import { FeatureContainer, FeatureButton } from "./FeatureElements";

function Feature() {
  return (
    <FeatureContainer>
      <h1>Entregamos calidad profesional</h1>
      <p>
        La excelencia es nuestra meta, nuestros servicios llevan esta firma.
      </p>
      <FeatureButton>Contactar</FeatureButton>
    </FeatureContainer>
  );
}

export default Feature;
