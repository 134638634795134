import product1 from "../../images/product-1.jpg";
import product2 from "../../images/product-2.jpg";
import product3 from "../../images/product-3.jpg";
import sweet1 from "../../images/sweet3.jpg";
import sweet2 from "../../images/sweet-2.jpg";
import sweet3 from "../../images/sweet-3.jpg";

export const productData = [
  {
    img: product1,
    // alt: 'Pizza',
    name: "3x2m Rectangular",
    desc: "Marinara sauce, basil, italian sausage, roma tomatoes, olives, and pesto",
    price: "Ksh 1000",
    button: "Add to Cart",
  },
  {
    img: product2,
    // alt: 'Pizza',
    name: "4x1.5m Ancha",
    desc: " Marinara sauce, basil, italian sausage, roma tomatoes, olives, and pesto",
    price: "Ksh 700",
    button: "Add to Cart",
  },
  {
    img: product3,
    // alt: 'Pizza',
    name: "1x3m Doble Vertical",
    desc: " Marinara sauce, basil, italian sausage, roma tomatoes, olives, and pesto",
    price: "Ksh 500",
    button: "Add to Cart",
  },
  {
    img: product3,
    // alt: 'Pizza',
    name: "2x1m Triple Vertical/Horizontal",
    desc: " Marinara sauce, basil, italian sausage, roma tomatoes, olives, and pesto",
    price: "Ksh 500",
    button: "Add to Cart",
  },
];

export const productDataTwo = [
  {
    img: sweet2,
    alt: "Doughnuts",
    name: "Eventos Corporativos",
    desc: "Belgian chocolate glazed doughnuts covered in icing with sprinkles on top",
    price: "Ksh 100",
    button: "Add to Cart",
  },
  {
    img: sweet3,
    alt: "Cumpleaños",
    name: "Cumpleaños",
    desc: "Vanilla ice cream covered with caramel and chocolate glaze topped with a coco stick",
    price: "Ksh 130",
    button: "Add to Cart",
  },
  {
    img: sweet1,
    alt: "Conciertos",
    name: "Conciertos",
    desc: "Double fudge brownie squares topped with white chocolate pieces and macadamia nuts",
    price: "Ksh 100",
    button: "Add to Cart",
  },
  {
    img: sweet1,
    alt: "Exposiciones",
    name: "Exposiciones",
    desc: "Double fudge brownie squares topped with white chocolate pieces and macadamia nuts",
    price: "Ksh 100",
    button: "Add to Cart",
  },
];
